import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";

const ImageSlider = () => {
    const images = [
        "/dia-de-suerte-Riffapp.png",
        "/dia-de-suerte-Riffapp.png",
        // Agrega más imágenes según sea necesario
      ];


      const settings = {
        dots: true, 
        infinite: images.length > 1, // Deshabilita el modo infinito si hay 1 imagen
        speed: 500,
        slidesToShow: 1, 
        slidesToScroll: 1,   
      };


    return (
      <Slider className="full-width" {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              className="border-3"
              src={image}
              alt={`Banner ${index + 1}`}
            />
          </div>
        ))}
      </Slider>
    );
  };
  
  export default ImageSlider;