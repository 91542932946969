import React from 'react';
import FAQItem from './FAQItem';

const FAQSection = () => {
  const faqData = [
    {
      question: "¿Cuáles son los métodos de pago que aceptan?",
      answer: "Aceptamos pagos a través de Pago Móvil."
    },
    {
      question: "¿Quién regula las rifas y dónde salen los resultados?",
      answer: "Nuestras rifas están reguladas por el Instituto Oficial de Beneficencia Pública y Asistencia Social del Estado Táchira (Lotería del Táchira). Los resultados se publican en las plataformas oficiales de La Lotería del Táchira."
    },
    {
      question: "¿Dónde retiraría mi premio en caso de ser el ganador?",
      answer: "Los premios se retiran directamente en las instalaciones de nuestros aliados comerciales."
    },
    {
      question: "¿Cuánto tiempo tengo para retirar mi premio?",
      answer: "Una vez anunciado el número ganador, tendrás 72 horas para retirar tu premio en las tiendas físicas del aliado comercial correspondiente."
    },
    {
      question: "¿Se realizan devoluciones?",
      answer: "No, tras la compra de uno o más tickets en nuestra plataforma, no se aceptan devoluciones."
    },
  ];

  return (
    <section>
      <div className="w-layout-blockcontainer container w-container">
        <div className="w-layout-vflex container faqs">
          <div className="w-layout-vflex div-text-rifas-calientes">
            <h4>Preguntas frecuentes</h4>
            <p className="font-medium text-center">
              Explora nuestra sección de preguntas frecuentes y tutoriales para encontrar respuestas rápidas y guías detalladas sobre el uso de Riffapp.
            </p>
          </div>

          <div className="w-layout-vflex container-faqs">
            {faqData.map((item, index) => (
              <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
