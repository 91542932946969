import React, { useEffect, useState } from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';


const StatusRaffle = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [raffleDetail, setRaffleDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [raffleDate, setRaffleDate] = useState(null); // Fecha de la rifa
    const [selectedNumbers, setSelectedNumbers] = useState([]); // Números seleccionados
    const [userRaffleCount, setUserRaffleCount] = useState(0);
    const [raffleImage, setRaffleImage] = useState([]);

    /* Obtener la cantidad de rifas asociadas al usuario */
    const fetchUserRaffleCount = async () => {
        const token = localStorage.getItem('token');
        const headers = token ? { Authorization: `Token ${token}` } : {};
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}raffles/user-purchases/`, { headers });
            setUserRaffleCount(response.data.count); // Actualizar el estado con la cantidad de rifas
        } catch (err) {
            setError('Error al obtener la cantidad de rifas del usuario.');
        }
    };


    /* Funcion para obtener numeros comprados por el usuario en esta rifa */
    const fetchUserPurchases = async () => {
        const token = localStorage.getItem('token');
        const headers = token ? { Authorization: `Token ${token}` } : {};
        try {
            // Llamar al endpoint con el ID de la rifa
            const response = await axios.get(`${process.env.REACT_APP_API_URL}raffles/purchase/purchased_numbers/`, {
                headers,
                params: {
                    raffle_id: id,
                },
            });
            // Actualizar estado con los números seleccionados
            setSelectedNumbers(response.data.purchased_numbers);
        } catch (err) {
            setError('Error al obtener los números seleccionados.');
        } finally {
            setLoading(false);
        }
    };

    /* Funcion para obtener el estado de la rifa */
    useEffect(() => {
        const fetchRaffleDetail = async () => {
            setLoading(true);
            const token = localStorage.getItem('token');
            const headers = token ? { Authorization: `Token ${token}` } : {};

            try {
                // Obtener los detalles de la rifa
                const response = await axios.get(`${process.env.REACT_APP_API_URL}raffles/raffle-status/${id}/`, { headers });
                setRaffleDetail(response.data);

                const raffleResponse = await axios.get(`${process.env.REACT_APP_API_URL}raffles/${id}/`, { headers });
                setRaffleDate(raffleResponse.data.end_date);
                setRaffleImage(raffleResponse.data.images);


                fetchUserPurchases(); // Llamar a la función al montar el componente
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRaffleDetail();
        fetchUserRaffleCount();
    }, [id]);

    if (loading) return <MoonLoader color="#ffca32" />;
    if (error) return <p>Error: {error}</p>;

    const handleRaffleClickResult = (id) => {
        navigate(`/result-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
    };

    const handleRaffleClickBuy = (id) => {
        navigate(`/info-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
    };

    const renderMessage = () => {
        if (raffleDetail.status === 0) {
            return (
                <div className='w-layout-vflex align-center container-notice bg-yellow'>
                    <h4 className='font-24px weight-800'>El sorteo será el día</h4>
                    {raffleDate && (
                        <p className='font-24px weight-800'>{new Date(raffleDate).toLocaleDateString('es-ES', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        })}</p>
                    )}
                </div>
            );
        } else if (raffleDetail.status === 4) {
            if (raffleDetail.is_winner) {
                return (
                    <div className='w-layout-vflex align-center container-notice bg-green'>
                        <h4 className='font-24px weight-800'>¡FELICIDADES!</h4>
                        <div className='w-layout-hflex gap-6'>
                            <p>Has ganado con el número</p>
                            <p className='weight-800'>{raffleDetail.winning_number}</p>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className='w-layout-vflex align-center container-notice bg-gray'>
                        <h4 className='font-24px weight-800'>No ha salido pero...</h4>
                        <p>Tienes {userRaffleCount} rifas y tu suerte se sigue acumulando</p>
                    </div>
                );
            }
        }
        return null;
    };

    const renderButton = () => {
        if (raffleDetail.status === 0) {
            return (
                <div key={raffleDetail.id} onClick={() => handleRaffleClickBuy(raffleDetail.id)} className='btn-primary w-button'>
                    Comprar más tickets
                </div>
            );
        } else {
            return (
                <div key={raffleDetail.id} onClick={() => handleRaffleClickResult(raffleDetail.id)} className='btn-primary w-button'>
                    Ver resultado
                </div>
            );
        }
    };

    return (
        <div>
            <Navbar />
            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-150 w-container">
                    <div className="grid-select-numbers">
                        <div className="w-layout-vflex">
                            <div className="container-details-pay w-form full-width">
                                <h4>{raffleDetail.title}</h4>

                                {renderMessage()}

                                <div className='w-layout-vflex gap-6 align-center'>
                                    <div className="font-graydark">Tus números en esta rifa son</div>

                                    {selectedNumbers.length > 0 ? (
                                        <div className="font-32 weight-600">
                                            <p>{selectedNumbers.join(' - ')}</p>
                                        </div>
                                    ) : (
                                        <div className="font-32 weight-600">
                                            <p>No has seleccionado números</p>
                                        </div>
                                    )}
                                </div>

                                <div className="w-layout-vflex duv-btn-buy-numbers">
                                    {/*{renderButton()}*/}
                                    <Link to={`/terms-and-conditions/${raffleDetail.id}`} className="link">
                                        Ver términos y condiciones de esta rifa
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <img
                            src={`${raffleImage?.[0]?.image || '/imagen-no-disponible.jpg'}`}
                            loading="lazy"
                            alt=""
                            className="img-w-border"
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default StatusRaffle;
