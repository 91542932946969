import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import UserContext from '../context/UserContext'; 
import { MoonLoader } from 'react-spinners';

const ProtectedRoute = ({ children }) => {
  const { userInfo, loading } = useContext(UserContext);
  const location = useLocation();

  if (loading) {
    return <MoonLoader color="#ffca32" />;
  }

  if (!loading && !userInfo.access_token) {
    console.log('Redirigiendo al signup desde:', location.pathname);

    return (
      <Navigate
        to="/signup"
        state={{ from: location.pathname }} // Guarda la URL actual como origen
        replace
      />
      
    );
  }

  // Si hay token, muestra el componente hijo
  return children;
};

export default ProtectedRoute;

