import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const CodeVerification = ({ onNext, email }) => {
  const [codes, setCodes] = useState(['', '', '', '']);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiURL = process.env.REACT_APP_API_URL;
  const inputRefs = useRef([]);

  // Validar que solo sean números y longitud correcta
  const handleChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newCodes = [...codes];
      newCodes[index] = value;
      setCodes(newCodes);
      // Mover el foco al siguiente campo si se ingresó un dígito
      if (value && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, 4); // Obtener los primeros 4 dígitos
    const newCodes = [...codes];

    for (let i = 0; i < pasteData.length; i++) {
      if (/^\d*$/.test(pasteData[i])) {
        newCodes[i] = pasteData[i];
      }
    }

    setCodes(newCodes);

    // Mover el foco al último campo con datos
    const lastFilledIndex = newCodes.findIndex(code => code === '');
    if (lastFilledIndex > -1) {
      inputRefs.current[lastFilledIndex].focus();
    } else {
      inputRefs.current[3].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    const combinedCode = codes.join('');

    if (combinedCode.length !== 4) {
      setError('El código debe tener 4 dígitos.');
      return;
    }

    if (!email) {
      setError('El correo electrónico es requerido.');
      return;
    }

    setLoading(true);
    
    try {
      const response = await axios.post(
        `${apiURL}verify-reset-code/`,
        {
          email,
          code: combinedCode,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        // Si la verificación es exitosa, pasa al siguiente paso
        onNext(combinedCode); // Asegúrate de que el backend devuelva el código de verificación
      }
    } catch (err) {
      console.log('error:', err)
      if (err.response) {
        setError(err.response.data.error || 'Error al verificar el código.');
      } else {
        setError('Error de red. Inténtalo de nuevo más tarde.');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderInputFields = () => {
    return codes.map((code, index) => (
      <input
        key={index}
        ref={(el) => (inputRefs.current[index] = el)}
        className="fields field-code w-input"
        type="text"
        value={code}
        maxLength={1}
        onChange={(e) => handleChange(index, e.target.value)}
        onPaste={handlePaste}
        required
      />
    ));
  };

  return (
    <div className="w-layout-vflex div-log-in text-center">
      <p>Ingrese el código de verificación que acabamos de enviar a su dirección de correo electrónico.</p>
      
      <div className="form-block-log w-form">
        <form className="container-log-fields" onSubmit={handleSubmit}>
          <div className="w-layout-vflex div-code-password">
            {renderInputFields()}
          </div>
          {error && <div className="error-message"><div>{error}</div></div>}

          <input
            type="submit"
            data-wait="Please wait..."
            className="btn-primary w-button"
            value={'Verificar'}
            disabled={loading}
          />
        </form>
      </div>
      <div className="font-small">
        ¿No recibiste el código? <Link to="/login">Reenviar</Link>
      </div>
    </div>
  );
};

export default CodeVerification;
