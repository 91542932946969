import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import ImageSliderLogIn from './../ImageSlider/imagesSliderLogIn'


const SectionSearch = () => {

  return (

    <section>
      <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-120 w-container">
        <div className="banner-home pd-bottom-60-mobile">
          <SearchBar />
        </div>
        <ImageSliderLogIn/>
      </div>
    </section>
  );
}

export default SectionSearch;