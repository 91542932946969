import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
import Payment from '../components/Stripe/Payment';
import TasaBcv from '../components/TasaBcv';
import CountdownTimer from '../components/CountdownTrimer';
import { prefix } from 'rsuite/esm/internals/utils';




const DetailsPayRaffle = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { raffleDetail, selectedNumbers, rafflePurchaseId } = location.state || [];
    const formattedNumbers = Array.isArray(selectedNumbers)
        ? selectedNumbers.flat().filter(Boolean).join(' - ')
        : 'Ningún número seleccionado';
    const [isChecked, setIsChecked] = useState(false);
    const apiURL = process.env.REACT_APP_API_URL;
    const websocketURL = process.env.REACT_APP_WEBSOCKET_URL;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [price_per_ticket, setPrice_per_ticket] = useState(0); // Nuevo estado para el subtotal
    const [subtotal, setSubtotal] = useState(0); // Nuevo estado para el subtotal
    const [iva, setIva] = useState(0); // Nuevo estado para el IVA
    const [total, setTotal] = useState(0); // Nuevo estado para el total
    const [totalbs, setTotalBs] = useState(0);
    const [userId, setUserID] = useState(''); // Estado para el nombre del usuario
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [activeTab, setActiveTab] = useState('tab1');
    const [modalVisible, setModalVisible] = useState(false); // Estado para controlar la visibilidad del modal

    const [codigo, setCodigo] = useState('0412');
    const [phone, setPhone] = useState('');
    const [referencia, setReferencia] = useState('');
    const [monto, setMonto] = useState('');
    const montoSugerido = localStorage.getItem('monto_bolivares');
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const emisor = `${codigo}${phone}`;

    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState('');
    const [discountCode, setDiscountCode] = useState("");
    const [message, setMessage] = useState("");


    const raffleDate = raffleDetail.end_date;

    const togglePopup = () => {
        setModalVisible(true); // Mostrar el modal
    };

    const handleClose = () => {
        setModalVisible(false); // Mostrar el modal
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };


    useEffect(() => {
        obtenerDatosUsuario();

        const fetchDetailsRaffles = async () => {
            const token = localStorage.getItem('token');
            setLoading(true);
            try {
                const response = await axios.post(
                    `${apiURL}raffles/payment-detail/`,
                    {
                        raffle_id: raffleDetail.id,
                        numbers: selectedNumbers,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Token ${token}`,
                        },
                    }
                );

                // Asignar los valores recibidos de la API al estado
                console.log(response.data);
                const { price_per_ticket, subtotal, iva, total, total_bs } = response.data; // Asegúrate de que tu API devuelva estos campos
                setPrice_per_ticket(price_per_ticket);
                setSubtotal(subtotal);
                setIva(iva);
                setTotal(total);
                setTotalBs(total_bs);

            } catch (error) {
                setError('Error al cargar los detalles de pago.');

                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        fetchDetailsRaffles();

    }, [apiURL, raffleDetail.id, selectedNumbers]);


    useEffect(() => {
        const fetchBanks = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${apiURL}banks`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    },
                });
                if (response.status === 200) {
                    setBanks(response.data.results); // Asigna la lista de bancos al estado
                } else {
                    console.error('Error al obtener los bancos');
                }
            } catch (error) {
                console.error('Error al obtener los bancos:', error);
            }
        };

        fetchBanks();
    }, [apiURL]);


    useEffect(() => { // Conexión WebSocket para esperar la confirmación de pago
        const token = localStorage.getItem('token');
        const wsUrl = `${websocketURL}?token=${token}`;
        const ws = new WebSocket(wsUrl);
        ws.onopen = () => {
            console.log("Conexión WebSocket establecida para confirmación de pago.");

        };
        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log(event);
            const raffle = raffleDetail.id;
            if (data.notification === "00") {
                localStorage.removeItem('monto_bolivares');
                ws.close = () => {
                    console.log("Conexión WebSocket cerrada.");
                };
                // Redirige a la página de éxito cuando el pago es confirmado
                navigate('/success', {
                    state: {
                        selectedNumbers,
                        raffle
                    }
                });
            }

            if (data.notification === "01") {
                setError((prevError) => prevError || 'Hubo un error al procesar la transacción. Por favor, verifica los datos ingresados o inténtalo de nuevo más tarde.');
                setLoadingSubmit(false);
            }
        };
        ws.onerror = (error) => {
            console.error("Error en WebSocket:", error);
        };
        return () => {
            ws.close = () => {
                console.log("Conexión WebSocket cerrada.");

            };
        };
    }, [navigate]);


    const obtenerDatosUsuario = async () => {
        const token = localStorage.getItem('token'); // Obtiene el token desde localStorage
        try {
            const headers = {
                Authorization: `Token ${token}`, // Autenticación con el token
            };
            const response = await axios.get(`${apiURL}users/me/`, { headers });
            if (response.status === 200) {
                setUserID(response.data.national_id);
                setError('');
            } else {
                setError('Error al obtener los datos del usuario');
            }
        } catch (error) {
            console.error('Error al obtener los datos del usuario:', error);
            setError('Error al obtener los datos del usuario');
        }
    };

    const roundedNumber = (number) => Math.round(number * 100) / 100;

    const validateCode = async () => {
        if (!discountCode.trim()) {
            setMessage("Por favor, ingresa un código.");
            return;
        }

        try {
            const response = await axios.get(`${apiURL}validate-promotional-code/raffle/${raffleDetail.id}/purchase/${rafflePurchaseId}/promo_code/${discountCode}/`);
            if (response.data.valid) {
                setMessage("Código válido y activo.");
                setIsButtonDisabled(true);

                // Obtener el porcentaje de descuento
                const discountPercentage = response.data.percentage_discount; // Ejemplo: 20 para un 20%

                // Calcular el nuevo precio del ticket con el descuento
                const discountAmount = (price_per_ticket * discountPercentage) / 100;
                const newPrice = price_per_ticket - discountAmount;

                // Calcular el nuevo total con el descuento
                const newTotal = total - (total * discountPercentage) / 100;

                const newSubTotal = subtotal - (subtotal * discountPercentage) / 100;
                const newTotalBs = totalbs - (totalbs * discountPercentage) / 100;

                // Actualizar el estado con los nuevos valores
                setPrice_per_ticket(newPrice);
                setTotal(roundedNumber(newTotal));
                setSubtotal(roundedNumber(newSubTotal));
                setTotalBs(roundedNumber(newTotalBs));

            } else {
                setMessage("Código inválido o inactivo.");
            }
        } catch (error) {
            console.error("Error al validar el código:", error);
            setMessage("Error al validar el código.");
        }
    };



    const handleSubmit = async (event) => {
        const lastEightDigits = referencia.slice(-6);
        const IdBank = selectedBank.slice(-3)
        console.log("1. Información enviada al register", lastEightDigits, ",", totalbs, ",", emisor, ",", IdBank);
        event.preventDefault();
        setError('');
        setLoadingSubmit(true);
        const token = localStorage.getItem('token');

        // Validación: El número de referencia debe tener al menos 6 dígitos
        if (referencia.length < 6) {
            setError('El número de referencia debe tener al menos 6 dígitos.');
            setLoadingSubmit(false);
            return;
        }

        try {
            console.log("2. Información enviada al register", lastEightDigits, ",", totalbs, ",", emisor, ",", IdBank);

            const response = await axios.post(
                `${apiURL}payment/register/`,
                {
                    reference_number: lastEightDigits,
                    amount: totalbs,
                    phone: emisor,
                    bank_code: IdBank,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Cookie': 'django_language=es-ve',
                        'Authorization': `Token ${token}`,
                    }
                }
            );

            console.log(response);
            const raffle = raffleDetail.id;

        } catch (err) {

            console.error('Error:', err);
            setError((prevError) => prevError || 'Datos invalidos.');
            setLoadingSubmit(false);
        }
    };

    const handleTimeUp = () => {
        alert('El tiempo ha expirado. Por favor, vuelve a generar los números.');
        navigate(`/info-raffle/${raffleDetail.id}`);
    };




    return (
        <div>
            <Navbar />
            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-120 w-container">
                    <div className="grid-select-numbers">
                        <div className="w-layout-vflex">
                            <div className="container-details-pay w-form">
                                <h4>{raffleDetail.title || 'Nombre de la Rifa'}</h4>
                                <div className="font-graydark">
                                    Estas comprando los siguientes números para la rifa {raffleDetail.title || 'Nombre de la Rifa'}, cuyo sorteo será el {new Date(raffleDate).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', })} y tiene un valor de ${price_per_ticket} por ticket.
                                </div>
                                <div className="font-32 weight-600">{selectedNumbers.join(', ')}</div>

                                {/* Detalle de total a pagar */}
                                <div className="w-layout-vflex gap-10">
                                    <div className="weight-600">Total a pagar</div>
                                    <div className="w-layout-vflex gap-20">
                                        <div className="grid-details">
                                            <div className="font-graydark">
                                                Números
                                            </div>
                                            <div className="font-graydark">
                                                Valor por número
                                            </div>
                                            <div className="font-graydark">
                                                {selectedNumbers.length}
                                            </div>
                                            <div className="font-graydark">
                                            $ {price_per_ticket}
                                            </div>
                                        </div>
                                        <div className="grid-details total">
                                            {/*<div className="font-graydark">
                                                Subtotal
                                            </div>
                                            <div className="font-graydark text-right">
                                                {subtotal} $
                                            </div>
                                             <div className="font-graydark">
                                                IVA
                                            </div>
                                            <div className="font-graydark text-right">
                                                {iva} $
                                            </div> */}
                                            <div className="font-graydark weight-800">
                                                Total
                                            </div>
                                            <div className="font-graydark text-right weight-800">
                                            $ {total} 
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <div style={{ width: "100%", height: "1px", backgroundColor: "#ccc", margin: "10px 0" }} />
                                    <label htmlFor="discountCode" className="top">Código de descuento</label>
                                    <div className="contenedor bottom">
                                        <input
                                            type="text"
                                            id="discountCode"
                                            placeholder="Código"
                                            value={discountCode}
                                            onChange={(e) => setDiscountCode(e.target.value)}
                                        />
                                        <button
                                            onClick={validateCode}
                                            disabled={isButtonDisabled}
                                            style={{
                                                backgroundColor: isButtonDisabled ? "#b0b0b0" : "#ffcc00",
                                                color: isButtonDisabled ? "#666" : "#FFF",
                                                cursor: isButtonDisabled ? "not-allowed" : "pointer",
                                            }}
                                        >
                                            Validar código
                                        </button>
                                    </div>
                                    <p>{message}</p>
                                    <div style={{ width: "100%", height: "1px", backgroundColor: "#ccc", margin: "10px 0" }} />
                                </div>

                                {/* Deshabilitar el botón de pago si se ha alcanzado el límite */}
                                <div className="w-layout-vflex gap-10">
                                    <div className="weight-600">Métodos de pago</div>
                                    <div className="full-width tabs-metodos-pago w-tabs">
                                        <div className="container-tab w-tab-menu">
                                            <a className={classNames('tab-pago w-inline-block w-tab-link', { 'w--current': activeTab === 'tab1' })}
                                                onClick={() => handleTabClick('tab1')}>
                                                <div>Pago móvil</div>
                                            </a>
                                            {/* <a className={classNames('tab-pago w-inline-block w-tab-link', { 'w--current': activeTab === 'tab2' })}
                                                onClick={() => handleTabClick('tab2')}>
                                                <div>TDC</div>
                                            </a> */}
                                        </div>
                                        {isChecked && (
                                            <div className="w-tab-content">
                                                {activeTab === 'tab1' && <div data-w-tab="Tab 1" className="w-tab-pane">
                                                    <div className="w-layout-vflex container-metodo-pago">
                                                        <div className="alert-container">
                                                            <div className="alert-icon">!</div>
                                                            <div className="alert-box">
                                                                <p>El monto de su pago móvil debe ser exactamente igual al indicado en el campo de Bs.</p>
                                                            </div>
                                                        </div>
                                                        <div className="alert-container">
                                                            <div className="alert-box">
                                                                <p>Al reportar el pago, en el campo de "Referencia" es importante agregar únicamente los últimos seis (6) números de la referencia de transacción.</p>
                                                            </div>
                                                        </div>
                                                        <div className="w-layout-vflex gap-10 full-width">
                                                            <div className="font-indications">Para pagar ${total}, debes transferir un total de:</div>
                                                            <div className="_w-bg-yellow full-width text-center">Bs. {totalbs}</div>
                                                        </div>
                                                        <div className="w-layout-vflex gap-10 full-width">
                                                            <div className="font-indications">Mediante el pago móvil:</div>
                                                            <div className="_w-bg-yellow full-width text-center">04242558149</div>
                                                            <div className="_w-bg-yellow full-width text-center">{userId || 'Cargando...'}</div>
                                                            <div className="_w-bg-yellow full-width text-center">0169 - R4, Banco Microfinanciero, C.A. </div>
                                                        </div>
                                                        <div className="font-indications">Introduce tu cédula junto a nuestro número y banco al momento de realizar tu transferencia. No te preocupes, esta información solo será utilizada para verificar tu pago.</div>
                                                        <button className="btn-second w-button full-width" onClick={togglePopup}>Reportar pago</button>
                                                        {/* <div className="font-indications font-small"><span className="font-yellow">Importante:</span> Si realizaste el pago desde tu banco, la app lo registrará automáticamente. En caso de realizarlo desde una cuenta de terceros debes registrar el pago en el botón.</div> */}
                                                    </div>
                                                </div>
                                                }
                                                {activeTab === 'tab2' && <div data-w-tab="Tab 2" className="w-tab-pane">
                                                    <div className="full-width">
                                                        <div className="container-metodo-pago">
                                                            <Payment
                                                                raffle={raffleDetail.id}
                                                                selected_numbers={selectedNumbers}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        )}
                                        {!isChecked && (
                                            <div className="message">Debes aceptar los términos y condiciones para habilitar el botón de pago.</div>
                                        )}
                                    </div>
                                </div>
                                {/* Botón de comprar rifa */}
                                <div className="w-layout-vflex duv-btn-buy-numbers">
                                    <label className="container-checkbox-terms"> Aceptar
                                        <Link to={`/terms-and-conditions/${raffleDetail.id}`} className="checkbox-label link">
                                            términos y condiciones de esta rifa
                                        </Link>
                                        <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange} // Actualiza el estado cuando se hace click en el checkbox
                                        ></input>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="img-w-border">
                            <img src={`${raffleDetail?.images?.[0]?.image || '/imagen-no-disponible.jpg'}`} loading="lazy" alt="" className="img-w-border" />
                            <div className="container-countdown">
                                <CountdownTimer onTimeUp={handleTimeUp} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Modal */}
            {modalVisible && (
                <div className="bg-modal"
                    style={{ visibility: modalVisible ? 'visible' : 'hidden', opacity: modalVisible ? 1 : 0, transition: 'opacity 0.5s ease', }}>
                    <div className="modal-signup">
                        <div className="w-layout-vflex gap-16 full-width">
                            <div className="close-btn ami referencia">
                                <a onClick={handleClose}>
                                    <img loading="lazy" src="/btn-close-modal-ami.svg" alt="Close Button" />
                                </a>

                            </div>
                            <div className="w-layout-vflex gap-16">
                                <img loading="lazy" src="/icon-success.svg" alt="Success Icon" />
                                <div className="w-layout-vflex">
                                    <h4 className="font-18 weight-600">Reportar pago</h4>
                                </div>
                            </div>
                            <form className="w-layout-vflex full-width gap-16" onSubmit={handleSubmit}>

                                {/* Dropdown de bancos filtrados */}
                                <div className="w-layout-vflex full-width gap-10">
                                    <label htmlFor="selectBank" className="font-small font-graydark">Seleccionar Banco</label>
                                    <select
                                        className="fields w-select"
                                        name="selectBank"
                                        value={selectedBank}
                                        onChange={(e) => setSelectedBank(e.target.value)}
                                    >
                                        <option value="">Seleccione un banco</option>
                                        {Array.isArray(banks) ? (
                                            banks.map((bank) => (
                                                <option key={bank.id} value={bank.prefix}>
                                                    {bank.name}
                                                </option>
                                            ))
                                        ) : (
                                            <div>No hay rifas disponibles</div>
                                        )}
                                    </select>
                                </div>

                                <div className="w-layout-vflex full-width gap-10">
                                    <label htmlFor="referencia" className="font-small font-graydark">Número de Referencia</label>
                                    <input
                                        className="fields w-input"
                                        placeholder="Escribe los últimos 6 dígitos de la referencia"
                                        name="referencia"
                                        type="text"
                                        value={referencia}
                                        onChange={(e) => {
                                            const value = e.target.value.slice(0, 12);
                                            setReferencia(value);
                                        }}
                                    />
                                </div>
                                <div className="w-layout-vflex full-width gap-10">
                                    <label htmlFor="referencia" className="font-small font-graydark">Número emisor</label>
                                    <div className="w-layout-hflex full-width">
                                        <select className="fields select-field left-field w-select" value={codigo} onChange={(e) => setCodigo(e.target.value)}>
                                            <option value="0412">0412</option>
                                            <option value="0414">0414</option>
                                            <option value="0424">0424</option>
                                            <option value="0416">0416</option>
                                            <option value="0426">0426</option>
                                        </select>
                                        <input
                                            className="fields w-input right-field"
                                            type="number"
                                            value={phone}
                                            onChange={(e) => {
                                                const value = e.target.value.slice(0, 7);
                                                setPhone(value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="w-layout-vflex full-width gap-10">
                                    <label htmlFor="referencia" className="font-small font-graydark">Monto</label>
                                    <input
                                        className="fields w-input"
                                        name="monto"
                                        type="text"
                                        value={totalbs}
                                        readOnly
                                    />
                                </div>

                                {/* Loader encima del botón */}
                                {loadingSubmit && (
                                    <div className="loader">
                                        <img src="/loader.gif" alt="Cargando..." />
                                    </div>
                                )}

                                {error && <div className="error-message"><div>{error}</div></div>}

                                <input
                                    type="submit"
                                    value="Validar"
                                    className={`btn-second w-button ${loadingSubmit ? 'disable' : ''}`}
                                    disabled={loadingSubmit} />
                            </form>
                        </div>
                    </div>
                </div>
            )}

            <Footer />
        </div>
    );
};

export default DetailsPayRaffle;
