import React, { useState } from 'react';

const ContactForm = () => {
  const [error, setError] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [codigo, setCodigo] = useState('0412');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const apiURL = process.env.REACT_APP_API_URL;
  const telconcatenado = `${codigo}${phone}`;

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(telconcatenado);

    try {
      const response = await fetch(
        `${apiURL}contact/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Cookie': 'django_language=es-ve', // Añadir el cookie header si es necesario
          },
          body: JSON.stringify({
            first_name: name,
            last_name: lastname,
            email: email.toLowerCase(),
            phone: telconcatenado,
            message: message,
          }),
        }
      );

      if (response.ok) {
        setResponseMessage('¡Gracias por comunicarte con nosotros, te contactaremos pronto! 🎉');
        setError(''); 
        setEmail('');
        setName('');
        setLastname('');
        setCodigo('0412');
        setPhone('');
        setMessage('');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Ocurrió un error al enviar el formulario.');
        setResponseMessage('');
      }
    } catch (err) {
      setError('Error de red. Inténtalo de nuevo más tarde.');
      setResponseMessage(''); 
    }
  };

  return (
    <div className="w-layout-vflex container-contact-form pd-top-120">
      <div className="w-layout-vflex container-form-contact">
        <div className="w-layout-vflex gap-20">
          <h4>Contáctanos</h4>
          <p className="font-20 font-graydark">En Riffapp, estamos aquí para ayudarte a resolver cualquier duda o inquietud que tengas.</p>
        </div>
        <div className="form-block-log w-form">
          <form className="container-log-fields" onSubmit={handleSubmit}>
            <div className="grid-field-contact">
              <div className="w-layout-vflex gap-10">
                <label className="font-small font-graydark">Nombre</label>
                <input
                  className="fields w-input"
                  type="text"
                  placeholder="Nombre"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="w-layout-vflex gap-10">
                <label className="font-small font-graydark">Apellido</label>
                <input
                  className="fields w-input"
                  type="text"
                  placeholder="Apellido"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
              </div>
            </div>
            <div className="w-layout-vflex gap-10">
              <label className="font-small">Email</label>
              <input
                className="fields w-input"
                type="email"
                placeholder="nombre@empresa.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            

            {/* Campo de telefono */}
            <div className="w-layout-vflex gap-10">
              <label htmlFor="Email-sing-up" className="font-small font-graydark">Teléfono*</label>
              <div className="w-layout-hflex full-width">
                <select className="fields select-field left-field w-select" value={codigo} onChange={(e) => setCodigo(e.target.value)}>
                  <option value="0412">0412</option>
                  <option value="0414">0414</option>
                  <option value="0424">0424</option>
                  <option value="0416">0416</option>
                  <option value="0426">0426</option>
                </select>
                <input
                  className="fields w-input right-field"
                  placeholder="Ingresa tu número telefónico"
                  type="number"
                  value={phone}
                  onChange={(e) => {
                    const value = e.target.value.slice(0, 7);
                    setPhone(value);
                  }}
                />
              </div>
            </div>



            <div className="w-layout-vflex gap-10">
              <label className="font-small font-graydark">Mensaje</label>
              <textarea
                className="fields w-input"
                placeholder="Escribe tu mensaje..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <input type="submit" value="Enviar" className="btn-primary w-button" />

            {responseMessage && (
            <div className="message winner">
              <div>{responseMessage}</div>
            </div>
          )}

          {error && (
            <div className="w-layout-vflex gap-10 full-width">
              <div className="error-message">{error}</div>
            </div>
          )}
          </form>

          
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
