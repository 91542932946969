import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useRaffles from '../../hooks/useRaffles';
import { MoonLoader } from 'react-spinners';

function HotRaffles() {
    const navigate = useNavigate(); // Hook para navegar a otra ruta
    const queryParams = useMemo(() => ({ is_hot: true, status: 0 }), []); const { raffles, loading, error } = useRaffles(queryParams);

    const handleRaffleClick = (id) => {
        navigate(`/info-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
    };

    // Función para truncar texto
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    if (loading) return <MoonLoader color="#ffca32" />;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="full-width">
            <div className="grid-rifas-calientes">
                {Array.isArray(raffles) && raffles.length > 0 ? (
                    raffles.map((raffle) => (
                        <div
                            key={raffle.id}
                            className="w-layout-vflex div-rifa-caliente container-hot-raffles"
                            onClick={() => handleRaffleClick(raffle.id)}
                            style={{
                                backgroundImage: `url(${raffle?.images?.[0]?.image || '/imagen-no-disponible.jpg'})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <div className="price-rifa-caliente">${Number(raffle?.ticket_price).toFixed(2)}</div>
                            <div className="w-layout-vflex z-index-10">
                                <div className="font-20 font-light weight-500">{raffle.title}</div>
                                <div className="font-small font-gray">{truncateText(raffle.description, 100)}</div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='message full-width'>No hay rifas activas en este momento.</div>
                )}
            </div>
        </div>
    );
}

export default HotRaffles;