import React, { useState, useEffect } from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import CountdownTimer from '../components/CountdownTrimer';

const SelectBookRaffle = () => {
    const [quantity, setQuantity] = useState(1);
    const location = useLocation();
    const { raffleDetail } = location.state || {};
    const navigate = useNavigate();
    const apiURL = process.env.REACT_APP_API_URL;

    const [numbers, setNumbers] = useState([]);
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const [limitReached, setLimitReached] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchBookletNumbers();
    }, []);

    useEffect(() => {
        fetchBookletNumbers()
    }, [quantity]);


    const fetchBookletNumbers = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(
                `${apiURL}raffles/booklet/${raffleDetail.id}/booklet/?quantity=${quantity}`,
                {
                    headers: {
                        Authorization: `Token ${localStorage.getItem('token')}`,
                    },
                }
            );
            setNumbers(response.data.numbers || []);
            setSelectedNumbers(response.data.numbers || []);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const serverMessage = error.response.data?.error || 'Error desconocido en el servidor';
                    setError(serverMessage);
                    console.error('Error del servidor:', serverMessage);
                } else {
                    const message = 'No se pudo conectar al servidor. Por favor, verifica tu conexión.';
                    setError(message);
                    console.error('Error sin respuesta del servidor:', error.message);
                }
            } else {
                const message = 'Ha ocurrido un error inesperado. Inténtalo más tarde.';
                setError(message);
                console.error('Error no controlado:', error.message);
            }
        } finally {
            setLoading(false);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Enviando formulario...");
        console.log("Números seleccionados:", selectedNumbers);
        if (selectedNumbers.length === 0) {
            console.error("No hay números seleccionados.");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(
                `${apiURL}raffles/purchase/`,
                {
                    raffle: raffleDetail.id,
                    selected_numbers: selectedNumbers,
                    terms_accepted: true,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${localStorage.getItem('token')}`,
                    },
                }
            );

            if (response.status === 200) {
                setLimitReached(false);
                const rafflePurchaseId = response.data.raffle_purchase.id
                navigate('/details-pay-raffle', { state: { raffleDetail, selectedNumbers, rafflePurchaseId } });
                /* const { clientSecret, paymentIntentId } = response.data;
                localStorage.setItem('clientSecret', clientSecret);
                localStorage.setItem('paymentIntentId', paymentIntentId); */
            }
        } catch (error) {
            if (error.response?.status === 400) {
                const errorDetail = error.response.data?.error?.non_field_errors || [];
                const maxTickets = raffleDetail.purchase_limit_per_user ? raffleDetail.purchase_limit_per_user : 10;
                console.log(errorDetail);
                if (errorDetail.includes("Has alcanzado el límite de compras para esta rifa.")) {
                    setError(`Este perfil ha alcanzado el número máximo permitido de tickets para esta rifa. Ya ha seleccionado un total de <strong>${maxTickets} números</strong>.`);
                } else if (errorDetail.includes("Uno o más números ya han sido comprados.")){
                    setError("Uno o más números ya han sido comprados.");
                } else if (errorDetail.includes("Uno o más números seleccionados están fuera del rango permitido.")){
                    setError("Uno o más números seleccionados están fuera del rango permitido.");
                } else {
                    setError('Hubo un error al realizar la compra. Inténtalo de nuevo.');
                }
            } else {
                setError('Hubo un error al realizar la compra. Inténtalo de nuevo.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleTimeUp = () => {
        alert('El tiempo ha expirado. Por favor, vuelve a generar los números.');
        navigate(`/info-raffle/${raffleDetail.id}`);
    };
    const maxTickets = raffleDetail.purchase_limit_per_user ? raffleDetail.purchase_limit_per_user : 10;

    return (
        <div>
            <Navbar />
            <section>
                <form onSubmit={handleSubmit}>
                    <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-150 w-container">
                        <div className="grid-select-numbers">
                            <div className="w-layout-vflex gap-32">
                                <h4>{raffleDetail?.title || 'Rifa'}</h4>

                                {limitReached && (
                                    <div className="error-message full-width error-big">
                                        Este perfil ha alcanzado el número máximo permitido de tickets para esta rifa. Ya ha seleccionado un total de <strong>${maxTickets} números</strong>.
                                    </div>
                                )}

                                <div className="w-form full-width">
                                    <div className="container-tabs-numbers">
                                        <p className='font-small font-graydark nomarginBottom'>Selecciona la cantidad de talonarios que desees. Recuerda que cada talonario incluye 10 tickets.</p>

                                        {error && (
                                            <div className="error-message full-width error-big" dangerouslySetInnerHTML={{ __html: error }} />
                                        )}

                                        <div className='quantity-selector'>
                                            <button
                                                type="button"
                                                className="btn-quantity w-button"
                                                onClick={() => setQuantity(Math.max(1, quantity - 1))}
                                                disabled={quantity <= 1}>
                                                -
                                            </button>
                                            <input type="text" value={quantity} readOnly className="input-quantity" />
                                            <button
                                                type="button"
                                                className="btn-quantity w-button"
                                                onClick={() => setQuantity(quantity + 1)}>
                                                +
                                            </button>
                                        </div>

                                        <button
                                            type="submit"
                                            className={`btn-second w-button ${!!error || loading ? 'disable' : ''}`}
                                            disabled={!!error || loading}>
                                            {raffleDetail?.ticket_price === "0.00" ? "Obtener rifa" : "Comprar rifa"}
                                        </button>

                                        <a href={`/terms-and-conditions/${raffleDetail?.id}`} className="link">
                                            Ver términos y condiciones de esta rifa
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="img-w-border">
                                <img src={`${raffleDetail?.images?.[0]?.image || '/imagen-no-disponible.jpg'}`} loading="lazy" alt="" className="img-w-border" />
                                <div className="container-countdown">
                                    <CountdownTimer onTimeUp={handleTimeUp} />
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </section>
            <Footer />
        </div>
    );
};

export default SelectBookRaffle;


