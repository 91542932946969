import React from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
    return (
        <div>
            <Navbar />

            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-120 w-container">
                    <div className="w-layout-vflex container-rifas-calientes">
                        <div className="w-layout-vflex div-text-rifas-calientes">
                            <div className="font-small _w-bg-yellow">Tu privacidad importa</div>
                            <h4 className="text-center">Políticas de Privacidad de RiffApp</h4>
                        </div>
                        <div className="w-layout-vflex">
                            <p className='letter-strong font-small'>Útima actualización: 17/02/2025</p>
                            <div className='container-paragraph'>
                                <h3 className='letter-strong font-24px'>1. Introducción</h3>
                                <p>
                                    Riffapp valora la privacidad de sus usuarios y se compromete a proteger sus datos personales. Esta Política de Privacidad describe cómo recopilamos, usamos y protegemos la información personal de los usuarios registrados en nuestra plataforma, donde pueden comprar rifas y participar en sorteos.
                                </p>
                                <p>Al utilizar Riffapp, aceptas los términos de esta Política de Privacidad. Si no estás de acuerdo con nuestras prácticas, te recomendamos no utilizar nuestros servicios.</p>
                            </div>
                            <div className='container-paragraph'>
                                <h3 className='letter-strong font-24px'>2. Información que recopilamos</h3>
                                <ul>
                                    <li>
                                        <strong>Información de Registro:</strong> Nombre completo, correo electrónico, número de teléfono, contraseñ y datos bancarios necesarios para los pagos a través de pago móvil.
                                    </li>
                                    <li>
                                        <strong>Información del Dispositivo y Uso:</strong> Datos como dirección IP, tipo de navegador, sistema operativo y patrones de uso dentro de la plataforma.
                                    </li>
                                    <li>
                                        <strong>Datos de Pago:</strong> Información necesaria para procesar transacciones seguras (sin almacenar detalles sensibles de tarjetas de crédito/débito, ya que utilizamos procesadores de pago externos).
                                    </li>
                                    <li>
                                        <strong>Historial de Compras:</strong> Rifas compradas, fechas de transacciones y participaciones en sorteos.
                                    </li>
                                    <li>
                                        <strong>Cookies:</strong> Información sobre cómo interactúas con nuestra plataforma (ver <a href="/cookie-policy">Política de Cookies</a>).
                                    </li>
                                </ul>
                            </div>
                            <div className='container-paragraph'>
                                <h3 className='letter-strong font-24px'>3. Uso de la información</h3>
                                <p>Usamos los datos recopilados para:</p>
                                <ul>
                                    <li>Procesar la compra de rifas y gestionar sorteos.</li>
                                    <li>Confirmar la identidad del usuario y garantizar la seguridad de las transacciones.</li>
                                    <li>Comunicarnos con los usuarios sobre su participación en sorteos, premios ganados y actualizaciones del servicio.</li>
                                    <li>Cumplir con las leyes y regulaciones aplicables en Venezuela.</li>
                                    <li>Mejorar la experiencia del usuario mediante el análisis de datos y la personalización del servicio.</li>
                                </ul>
                            </div>
                            <div className='container-paragraph'>
                                <h3 className='letter-strong font-24px'>4. Cómo protegemos tu información</h3>
                                <p>
                                    Riffapp implementa medidas de seguridad administrativas, técnicas y físicas para proteger los datos personales de los usuarios contra accesos no autorizados, alteraciones y divulgaciones indebidas.
                                </p>
                                <p>Entre nuestras medidas de seguridad se incluyen:</p>
                                <ul>
                                    <li>Encriptación de datos sensibles.</li>
                                    <li>Restricción de acceso a información personal solo a empleados autorizados.</li>
                                    <li>Auditorías periódicas de seguridad.</li>
                                </ul>
                            </div>
                            <div className='container-paragraph'>
                                <h3 className='letter-strong font-24px'>5. Compartición de datos con terceros</h3>
                                <p>
                                    No vendemos ni compartimos información personal con terceros, excepto en los siguientes casos:
                                </p>
                                <ul>
                                    <li>Proveedores de Servicios: Compartimos datos con terceros que nos ayudan a procesar pagos y garantizar la operación de la plataforma.</li>
                                    <li>Obligaciones Legales: Cuando sea requerido por ley, podemos divulgar información a autoridades competentes.</li>
                                    <li>Prevención de Fraude: Para proteger la integridad de la plataforma y evitar actividades fraudulentas.</li>
                                </ul>
                            </div>
                            <div className='container-paragraph'>
                                <h3 className='letter-strong font-24px'>6. Tus derechos</h3>
                                <p>Los usuarios de Riffapp tienen derecho a:</p>
                                <ul>
                                    <li>Acceder, corregir o eliminar su información personal.</li>
                                    <li>Solicitar la limitación del uso de sus datos personales.</li>
                                    <li>Retirar su consentimiento para el tratamiento de datos (cuando sea aplicable).</li>
                                    <li>Presentar una reclamación ante las autoridades de protección de datos si consideran que sus derechos han sido vulnerados.</li>
                                </ul>
                                <p>Para ejercer estos derechos, puedes contactarnos a <a href="mailto:info@riffapp.net">info@riffapp.net</a>.</p>
                            </div>
                            <div className='container-paragraph'>
                                <h3 className='letter-strong font-24px'>7. Almacenamiento y retención de datos</h3>
                                <p>
                                    Riffapp conserva la información personal de los usuarios mientras sea necesario para proporcionar nuestros servicios y cumplir con requisitos legales. Posteriormente, los datos se eliminan o anonimizan de manera segura.
                                </p>
                            </div>
                            <div className='container-paragraph'>
                                <h3 className='letter-strong font-24px'>8. Menores de edad</h3>
                                <p>
                                    Riffapp está diseñada para usuarios mayores de 18 años. No recopilamos intencionadamente información de menores de edad. Si detectamos que un menor ha proporcionado datos personales, los eliminaremos inmediatamente.
                                </p>
                            </div>
                            <div className='container-paragraph'>
                                <h3 className='letter-strong font-24px'>9. Cambios en esta política de privacidad</h3>
                                <p>
                                    Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Notificaremos a los usuarios sobre cambios significativos mediante correo electrónico o anuncios en la plataforma.
                                </p>
                            </div>
                            <div className='container-paragraph'>
                                <h3 className='letter-strong font-24px'>10. Contacto</h3>
                                <p>Si tienes preguntas sobre esta Política de Privacidad o sobre el uso de tus datos personales, puedes contactarnos mediante <a href="mailto:info@riffapp.net">info@riffapp.net</a>.</p>
                            </div>
                        </div>
                        <p className='letter-strong'>Gracias por confiar en Riffapp. Tu privacidad y seguridad son nuestra prioridad</p>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
