import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import Analytics from './components/Analytics';
import Login from './pages/LoginPage';
import HomePage from './pages/HomePage';
import SignupSection from './pages/SignupPage';
import InfoRaffle from './pages/InfoRafflePage';
import SelectNumberRaffle from './pages/SelectNumberRafflePage';
import DetailsPayRaffle from './pages/DetailsPayRafflePage';
import MyProfile from './pages/ProfilePage';
import MyRaffles from './pages/MyRafflesPage';
import ResetPassword from './pages/ResetPasswordPage';
import ResultSearchPage from './pages/ResultSearchPage';
import StatusRaffle from './pages/StatusMyRafflePage';
import ResultRaffle from './pages/ResultRafflePage';
import UserProfile from './pages/UserProfilePage';
import BuySuccess from './pages/SuccessPage';
import BecomeRiffaper from './pages/BecomeRiffapperPage';
import RiffaperProfile from './pages/RiffapperProfilePage';
import { CopiadoProvider } from './components/Riffapper/CopiadoContext';
import RaffleTermsandConditions from './pages/TermsAndConditions';
import { UserProvider } from './context/UserContext';
import ProtectedRoute from './components/ProtectedRoute';
import ContactPage from './pages/Help';
import ConfigProfile from './pages/ConfigProfilePage';
import SelectBookRaffle from './pages/SelectBookRafflePage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import TermsAndConditions from './pages/TermsAndCondition';
import ScrollToTop from './components/ScrollToTop';


function App() {
  const [email, setEmail] = useState('');

  const handleNext = (email) => {
    setEmail(email);
  };


  return (
    <CopiadoProvider>
      <Router>
        <ScrollToTop />
        <UserProvider>
          <Analytics />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignupSection />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path='/help' element={<ContactPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/info-raffle/:id" element={<InfoRaffle />} />


            {/* Rutas protegidas */}
            
            <Route path="/select-number-raffle/:id" element={<ProtectedRoute><SelectNumberRaffle /></ProtectedRoute>} />
            <Route path="/select-book-raffle/:id" element={<ProtectedRoute><SelectBookRaffle /></ProtectedRoute>} />
            <Route path="/home-log-in" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
            <Route path="/my-raffles" element={<ProtectedRoute><MyRaffles /></ProtectedRoute>} />
            <Route path="/user-profile" element={<ProtectedRoute><ConfigProfile /></ProtectedRoute>} />
            <Route path='/status-raffle/:id' element={<StatusRaffle />} />
            <Route path='/result-raffle/:id' element={<ProtectedRoute><ResultRaffle /></ProtectedRoute>} />
            <Route path='/success' element={<ProtectedRoute><BuySuccess /></ProtectedRoute>} />
            <Route path="/results" element={<ProtectedRoute><ResultSearchPage /></ProtectedRoute>} />
            <Route path="/details-pay-raffle" element={<ProtectedRoute><DetailsPayRaffle /></ProtectedRoute>} />
            <Route path='/become-riffapper' element={<ProtectedRoute><BecomeRiffaper /></ProtectedRoute>} />
            <Route path='/profile-riffapper' element={<ProtectedRoute><RiffaperProfile /></ProtectedRoute>} />
            <Route path='/terms-and-conditions/:id' element={<ProtectedRoute><RaffleTermsandConditions /></ProtectedRoute>} />
          </Routes>
        </UserProvider>
      </Router>
    </CopiadoProvider>
  );
}

export default App;
